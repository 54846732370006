<template>
  <div class="row" v-if="inspection">
    <div class="col-md-3">
      <h4 class="text-secondary">{{ inspection.name }}</h4>
      <h6 v-if="inspection.location && inspection.location.length > 0">
        {{ inspection.location }}
      </h6>
      <p>
        {{ inspection.address }}, {{ inspection.zipCode }}
        {{ inspection.city }}
        <br />
        {{ inspection.type }}-Inspektion
        <br />
        <small>Rmg-sags nr. {{ inspection.externalRef }}</small>
      </p>
      <p v-if="inspection.status">
        <label>Aktuel status på sagen</label>
        <StatusDisplay
          :status="inspection.status"
          :experationDate="inspection.expirationDate"
        ></StatusDisplay>
      </p>
      <router-link
        v-if="inspection.status === 1"
        v-slot="{ navigate }"
        custom
        :to="{
          name: 'inspection_error',
          params: { id: inspection.customerId, inspection: inspection.id }
        }"
      >
        <button type="button" class="btn btn-primary" @click="navigate">
          Registrer fejlretning
        </button>
      </router-link>
    </div>
    <div class="col-md-9">
      <ul class="nav nav-pills nav-fill" id="inspectionTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            data-bs-toggle="tab"
            data-bs-target="#document-tab"
            type="button"
            role="tab"
            aria-controls="document-tab"
            aria-selected="true"
            href="#"
          >
            Rapporter
          </a>
        </li>
        <!--<li class="nav-item">
          <a
            class="nav-link"
            href="#"
            data-bs-toggle="tab"
            data-bs-target="#logs-tab"
            role="tab"
            aria-controls="logs-tab"
            aria-selected="true"
          >
            Hændelser
          </a>
        </li>-->
      </ul>
      <div class="tab-content" id="inspectionTab">
        <div
          class="tab-pane fade active show"
          id="document-tab"
          role="tabpanel"
          aria-labelledby="document-tab"
        >
          <Documents :inspectionId="inspection.id"></Documents>
        </div>
        <div
          class="tab-pane fade"
          id="logs-tab"
          role="tabpanel"
          aria-labelledby="logs-tab"
        >
          <Logs :inspectionId="inspection.id"></Logs>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  ref,
  watch
} from 'vue';
import { customerState } from '@/store';
import InspectionViewModel from '@/Model/InspectionViewModel';
import useInspection from '@/store/useInspection';
import { useRoute } from 'vue-router';
import { Tooltip } from 'bootstrap';

export default defineComponent({
  components: {
    StatusDisplay: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "inspection" */ '@/components/StatusDisplay.vue'
        )
    ),
    Documents: defineAsyncComponent(
      () => import(/* webpackChunkName: "inspection" */ './ListDocument.vue')
    ),
    Logs: defineAsyncComponent(
      () => import(/* webpackChunkName: "inspection" */ './InspectionLog.vue')
    )
  },

  setup() {
    const { get } = useInspection();
    const { params } = useRoute();
    const inspection = ref<InspectionViewModel | null>(
      customerState.inspection
    );
    customerState.$watch(
      (module) => module.inspection,
      (newValue: InspectionViewModel | null) => {
        inspection.value = newValue;
      }
    );
    onMounted(() => {
      const id = params.inspection;
      get(id).then((result: InspectionViewModel) => {
        inspection.value = result;
      });

      Array.from(
        document.querySelectorAll('button[data-bs-toggle="tooltip"]')
      ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    });

    return {
      inspection
    };
  }
});
</script>
<style lang="scss" scoped>
.card {
  border: none;
}
</style>
