<template>
  <div class="customer">
    <ListCustomer></ListCustomer>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { appState } from '@/store';
import ListCustomer from './components/ListCustomer.vue'; // @ is an alias to /src

export default defineComponent({
  components: {
    ListCustomer
  },
  setup() {
    appState.updateMessageCount();
  }
});
</script>
