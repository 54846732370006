<template>
  <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li v-if="inspection && customer" class="breadcrumb-item">
          <router-link :to="{ name: 'inspection', parms: { id: customer.id } }">
            {{ customer.name }}
          </router-link>
        </li>
        <li
          v-if="inspection && customer && showInspection"
          class="breadcrumb-item"
        >
          <router-link
            :to="{
              name: 'inspection_documents',
              parms: { id: customer.id, inspection: inspection.id }
            }"
          >
            {{ inspection.name }}
          </router-link>
        </li>
      </ol>
  </nav>
  <router-view></router-view>
</template>
<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import useInspection from '@/store/useInspection';
import useCustomer from '@/store/useCustomer';

import { customerState } from '@/store';
import CustomerViewModel from '@/Model/CustomerViewModel';
import InspectionViewModel from '@/Model/InspectionViewModel';
import { useRoute } from 'vue-router';
export default defineComponent({
  setup() {
    const { params, name } = useRoute();
    const customer = computed<CustomerViewModel | null>(
      () => customerState.customer
    );
    const inspection = computed<InspectionViewModel | null>(
      () => customerState.inspection
    );
    const showInspection = computed<boolean>(() => {
      return name === 'inspection_error';
    });
    onMounted(() => {
      if (!customer.value) {
        const { get } = useCustomer();
        get(params.id).then((response: CustomerViewModel) => {
          customerState.setCustomer(response);
          if (!inspection.value && params.inspection) {
            const { get } = useInspection();
            get(params.inspection).then((response: InspectionViewModel) => {
              customerState.setInspection(response);
            });
          }
        });
      }
    });

    return {
      customer,
      inspection,
      showInspection
    };
  }
});
</script>
