<template>
  <table class="table table-sm table-hover" v-if="customers">
    <thead>
      <tr>
        <td class="text-start">Navn</td>
        <td class="text-start">Adresse</td>
        <td class="text-start">Lokation</td>
        <td class="text-start">Postnr og by</td>
      </tr>
    </thead>
    <tbody>
      <template v-for="customer in customers" :key="customer.id">
        <router-link
          v-if="customer.customerType === 1"
          v-slot="{ navigate }"
          custom
          :to="{ name: 'inspection', params: { id: customer.id } }"
        >
          <tr @click="navigate">
            <td class="text-start">{{ customer.name }}</td>
            <td class="text-start">
              {{ customer.address }}
            </td>
            <td class="text-start">
              {{ customer.location }}
            </td>
            <td class="text-start">
              {{ customer.zipCode }} {{ customer.city }}
            </td>
          </tr>
        </router-link>
        <router-link
          v-else
          v-slot="{ navigate }"
          custom
          :to="{
            name: 'inspection_documents',
            params: { id: customer.id, inspection: customer.customerId }
          }"
        >
          <tr @click="navigate">
            <td class="text-start">{{ customer.name }}</td>
            <td class="text-start">
              {{ customer.address }}
            </td>
            <td class="text-start">
              {{ customer.location }}
            </td>
            <td class="text-start">
              {{ customer.zipCode }} {{ customer.city }}
            </td>
          </tr>
        </router-link>
      </template>
    </tbody>
  </table>
</template>
<script lang="ts">
import { defineComponent, onMounted, Ref, ref } from 'vue';
import useCustomer from '@/store/useUserCustomer';
import CustomerViewModel from '@/Model/CustomerViewModel';
import { onBeforeRouteLeave } from 'vue-router';
import { customerState } from '@/store';
export default defineComponent({
  name: 'listCustomers',
  setup() {
    const { getCustomers } = useCustomer();
    const customers: Ref<Array<CustomerViewModel> | null> = ref<Array<CustomerViewModel> | null>(
      null
    );

    onMounted(() => {
      getCustomers().then((result: Array<CustomerViewModel>) => {
        customers.value = result;
      });
    });
    onBeforeRouteLeave((to) => {
      if (to.params.id && customers && customers.value) {
        const id = parseInt(to.params.id.toString(), 10);
        const index = customers.value?.findIndex((customer) => {
          return customer.id === id;
        });
        if (index !== -1) {
          customerState.setCustomer(customers.value[index]);
        }
      }
    });
    return { customers };
  }
});
</script>
