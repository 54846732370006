import axios, { AxiosResponse } from 'axios';
import IComandResult from '@/Model/IComandResultTyped';
import useAuthToken from '@/utillities/useAuthToken';
import InspectionViewModel from '@/Model/InspectionViewModel';
import DocumentViewModel from '@/Model/DocumentViewModel';

export default (): any => {
  const getInspections = async (
    id: number
  ): Promise<Array<InspectionViewModel>> => {
    await useAuthToken();
    return new Promise<Array<InspectionViewModel>>((resolve, reject) => {
      axios
        .get(`/api/customer/${id}/inspection`)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<InspectionViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const exportToExcel = async (id: number): Promise<Blob> => {
    await useAuthToken();
    return new Promise<Blob>((resolve, reject) => {
      axios
        .get(`/api/customer/${id}/inspection/export`, {
          responseType: 'arraybuffer'
        })
        .then((response: AxiosResponse<Blob>) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getDocuments = async (
    id: number
  ): Promise<Array<DocumentViewModel>> => {
    await useAuthToken();
    return new Promise<Array<DocumentViewModel>>((resolve, reject) => {
      axios
        .get(`/api/Inspection/${id}/documents`)
        .then(
          (
            response: AxiosResponse<IComandResult<Array<DocumentViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const getDocument = async (id: number): Promise<Blob> => {
    await useAuthToken();
    return new Promise<Blob>((resolve, reject) => {
      axios
        .get(`/api/download/${id}`, { responseType: 'arraybuffer' })
        .then((response: AxiosResponse<Blob>) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  const get = async (id: number): Promise<InspectionViewModel> => {
    await useAuthToken();
    return new Promise<InspectionViewModel>((resolve, reject) => {
      axios
        .get(`/api/Inspection/${id}`)
        .then((response: AxiosResponse<IComandResult<InspectionViewModel>>) => {
          if (response.status == 200) {
            if (response.data.success) {
              resolve(response.data.data);
            } else {
              reject(response.data.errors);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return { getInspections, getDocuments, get, getDocument, exportToExcel };
};
