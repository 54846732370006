import ErrorCorrectionViewModel from '@/Model/ErrorCorrectionViewModel';
import useAuthToken from '@/utillities/useAuthToken';
import axios, { AxiosResponse } from 'axios';
import IComandResultTyped from '@/Model/IComandResultTyped';
import { ref } from 'vue';
import IComandResult from '@/Model/IComandResult';
import { appState } from '.';

export default (id: string): any => {
  const errorCorrection = ref<ErrorCorrectionViewModel>();
  const getErrorCorrection = async (): Promise<void> => {
    await useAuthToken();
    return new Promise<void>((resolve, reject) => {
      axios
        .get(`/api/Inspection/${id}/ErrorCorrection`)
        .then(
          (
            response: AxiosResponse<
              IComandResultTyped<ErrorCorrectionViewModel>
            >
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                if (response.data.data === null) {
                  appState.raiseError('Errorcorrection not found!!');
                } else {
                  errorCorrection.value = response.data.data;
                  resolve();
                }
              } else {
                appState.raiseError(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };
  const saveErrorCorrection = async (
    model: ErrorCorrectionViewModel
  ): Promise<void> => {
    await useAuthToken();
    if (model.id && model.id !== 0) {
      return new Promise<void>((resolve, reject) => {
        axios
          .put(`/api/Inspection/${model.inspection.id}/ErrorCorrection`, model)
          .then((response: AxiosResponse<IComandResult>) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve();
              } else {
                reject(response.data.errors);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        axios
          .post(`/api/Inspection/${model.inspection.id}/ErrorCorrection`, model)
          .then((response: AxiosResponse<IComandResult>) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve();
              } else {
                reject(response.data.errors);
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  };
  getErrorCorrection();
  return { errorCorrection, saveErrorCorrection, getErrorCorrection };
};
