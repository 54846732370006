
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  ref,
  watch
} from 'vue';
import useInspection from '@/store/useInspection';
import InspectionViewModel from '@/Model/InspectionViewModel';
import { useRoute } from 'vue-router';
import toDateString from '@/utillities/toDateString';
import { onBeforeRouteLeave } from 'vue-router';
import { appState, customerState } from '@/store';

import CustomerViewModel from '@/Model/CustomerViewModel';

export default defineComponent({
  name: 'listInspections',
  components: {
    StatusDisplay: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "inspection" */ '@/components/StatusDisplay.vue'
        )
    )
  },
  setup() {
    const { getInspections, exportToExcel } = useInspection();
    const inspections = ref<Array<InspectionViewModel>>();
    const filteredInspections = ref<Array<InspectionViewModel>>();
    const { params } = useRoute();
    const id = parseInt(params.id.toString(), 10);
    const statusSort = ref<string>('');
    const filter = ref<string>('');
    const customer = computed<CustomerViewModel | null>(
      () => customerState.customer
    );
    const exportAsExcell = () => {
      exportToExcel(id)
        .then((result: Blob) => {
          const blob = new Blob([result], {
            type: 'application/vnd.ms-excel'
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'inspections.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error: string[]) => {
          appState.raiseError(error);
        });
    };

    customerState.inspection = null;
    const filterAndSort = (filter: string, sort: number) => {
      if (filter === '' && sort !== -1) {
        const sortedList = inspections.value?.filter(
          (inspection: InspectionViewModel) => {
            if (inspection.status === sort) {
              return inspection;
            }
          }
        );
        filteredInspections.value = sortedList;
      } else if (filter !== '') {
        const filteredList = inspections.value?.filter(
          (inspection: InspectionViewModel) => {
            const searchValues = `${inspection.name} ${inspection.location} ${inspection.address} ${inspection.zipCode} ${inspection.city}`.toLowerCase();
            if (searchValues.includes(filter.toLowerCase())) {
              return inspection;
            }
          }
        );
        if (filteredList && sort !== -1) {
          const sortedList = filteredList.filter(
            (inspection: InspectionViewModel) => {
              if (inspection.status === sort) {
                return inspection;
              }
            }
          );
          filteredInspections.value = sortedList;
        } else {
          filteredInspections.value = filteredList;
        }
      } else {
        filteredInspections.value = inspections.value;
      }
    };
    watch(
      () => statusSort.value,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          let status: number = newValue != '' ? parseInt(newValue, 10) : -1;
          filterAndSort(filter.value, status);
        }
      }
    );
    watch(
      () => filter.value,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          let status: number =
            statusSort.value != '' ? parseInt(statusSort.value, 10) : -1;
          filterAndSort(filter.value, status);
        }
      }
    );
    onBeforeRouteLeave((to) => {
      if (to.params.inspection && inspections && inspections.value) {
        const id = parseInt(to.params.inspection.toString(), 10);
        const index = inspections.value?.findIndex((inspection) => {
          return inspection.id === id;
        });
        if (index !== -1) {
          customerState.setInspection(inspections.value[index]);
        }
      }
    });
    onMounted(() => {
      getInspections(id).then((result: Array<InspectionViewModel>) => {
        inspections.value = result;
        filteredInspections.value = inspections.value;
      });
    });
    return {
      filteredInspections,
      customer,
      toDateString,
      exportAsExcell,
      statusSort,
      filter
    };
  }
});
