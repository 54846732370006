
import { defineComponent, onMounted, Ref, ref } from 'vue';
import useCustomer from '@/store/useUserCustomer';
import CustomerViewModel from '@/Model/CustomerViewModel';
import { onBeforeRouteLeave } from 'vue-router';
import { customerState } from '@/store';
export default defineComponent({
  name: 'listCustomers',
  setup() {
    const { getCustomers } = useCustomer();
    const customers: Ref<Array<CustomerViewModel> | null> = ref<Array<CustomerViewModel> | null>(
      null
    );

    onMounted(() => {
      getCustomers().then((result: Array<CustomerViewModel>) => {
        customers.value = result;
      });
    });
    onBeforeRouteLeave((to) => {
      if (to.params.id && customers && customers.value) {
        const id = parseInt(to.params.id.toString(), 10);
        const index = customers.value?.findIndex((customer) => {
          return customer.id === id;
        });
        if (index !== -1) {
          customerState.setCustomer(customers.value[index]);
        }
      }
    });
    return { customers };
  }
});
