
import {
  defineAsyncComponent,
  defineComponent,
  onMounted,
  ref,
  watch
} from 'vue';
import { customerState } from '@/store';
import InspectionViewModel from '@/Model/InspectionViewModel';
import useInspection from '@/store/useInspection';
import { useRoute } from 'vue-router';
import { Tooltip } from 'bootstrap';

export default defineComponent({
  components: {
    StatusDisplay: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "inspection" */ '@/components/StatusDisplay.vue'
        )
    ),
    Documents: defineAsyncComponent(
      () => import(/* webpackChunkName: "inspection" */ './ListDocument.vue')
    ),
    Logs: defineAsyncComponent(
      () => import(/* webpackChunkName: "inspection" */ './InspectionLog.vue')
    )
  },

  setup() {
    const { get } = useInspection();
    const { params } = useRoute();
    const inspection = ref<InspectionViewModel | null>(
      customerState.inspection
    );
    customerState.$watch(
      (module) => module.inspection,
      (newValue: InspectionViewModel | null) => {
        inspection.value = newValue;
      }
    );
    onMounted(() => {
      const id = params.inspection;
      get(id).then((result: InspectionViewModel) => {
        inspection.value = result;
      });

      Array.from(
        document.querySelectorAll('button[data-bs-toggle="tooltip"]')
      ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    });

    return {
      inspection
    };
  }
});
