<template>
  <div class="row justify-content-md-center" v-if="errorCorrection">
    <div id="errorCorrection" class="col-md-8" v-if="!errorCorrection.isExpired">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Registrering af udbedret fejl på
            {{ errorCorrection.inspection.name }}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ errorCorrection.inspection.address }}
            <span v-if="errorCorrection.inspection.location">
              , {{ errorCorrection.inspection.location }}
            </span>
          </h6>
          <div class="mb-3 mt-3" v-if="receipt">
            <p>
              Din Registrering er modtaget, du vil modtage en godkendelse inden
              for kort tid
            </p>
            <span>
              <router-link
                :to="{
                  name: 'inspection_documents',
                  params: {
                    id: errorCorrection.inspection.customerId,
                    inspection: errorCorrection.inspection.id
                  }
                }"
              >
                Tilbage
              </router-link>
            </span>
          </div>
          <template v-else>
            <ErrorRegistration
              @onAdd="addErrorRegistration"
              @onDone="allErrorDone"
              :allowPartly="errorCorrection.allowPartlyCorrection"
            ></ErrorRegistration>
          </template>
        </div>
      </div>
    </div>
    <div id="errorCorrection" class="col-md-8" v-if="errorCorrection.isExpired">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            Registrering af udbedret fejl på
            {{ errorCorrection.inspection.name }}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{ errorCorrection.inspection.address }}
            <span v-if="errorCorrection.inspection.location">
              , {{ errorCorrection.inspection.location }}
            </span>
          </h6>
          <div class="mb-3 mt-3">
            <p>
              Fristen for tilbagemelding på, at B-fejl er udbedret, er
              overskredet den {{ toDateString(errorCorrection.expiresAt) }}, og det er
              derfor ikke længere muligt at tilbagemelde på linket.
            </p>
            <p>
              Du bedes derfor bekræfte udbedring af fejl pr. mail til
                <a href="mailto:rapport.rmg@kiwa.com">rapport.rmg@kiwa.com</a>
              . Vi vil herefter fremsende bekræftelse på godkendelse af
              anlægget. Ved tvivlsspørgsmål kontakt også venligst
                <a href="mailto:rapport.rmg@kiwa.com">rapport.rmg@kiwa.com</a>
              .
            </p>
            <span>
              <router-link
                :to="{
                  name: 'inspection_documents',
                  params: {
                    id: errorCorrection.inspection.customerId,
                    inspection: errorCorrection.inspection.id
                  }
                }"
              >
                Tilbage
              </router-link>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-md-4"
      v-if="errorCorrection.allowPartlyCorrection && errorCorrection.notes"
    >
      <h6 class="">Tidligere Registreringer</h6>
      <ul class="list-group list-group-flush">
        <li
          class="list-group-item p-0"
          v-for="note in errorCorrection.notes"
          :key="note.id"
        >
          <p class="text-start mb-1 mt-1">{{ note.description }}</p>
          <p class="text-start mb-0">
            <small>
              {{ toDateString(note.closed) }} af: {{ note.closedBy }}
            </small>
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  ref,
  toRaw
} from 'vue';
import { useRoute } from 'vue-router';
import { customerState, userModule } from '@/store';
import ErrorCorrectionViewModel from '@/Model/ErrorCorrectionViewModel';
import ErrorCorrectionNoteViewModel from '@/Model/ErrorCorrectionNoteViewModel';
import toDateString from '@/utillities/toDateString';
import useErrorCorrection from '@/store/useErrorCorrection';
import InspectionViewModel from '@/Model/InspectionViewModel';

export default defineComponent({
  name: 'ErrorCorrection',
  components: {
    ErrorRegistration: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "errorCorrection" */ '@/views/ErrorCorrection/components/errorRegistration.vue'
        )
    )
  },
  setup() {
    const { params } = useRoute();
    const id = params.inspection.toString();
    const receipt = ref<boolean>(false);
    const inspection = computed<InspectionViewModel | null>(
      () => customerState.inspection
    );
    const {
      errorCorrection,
      saveErrorCorrection: update,
      getErrorCorrection: load
    } = useErrorCorrection(id);

    const addErrorRegistration = (note: string) => {
      if (errorCorrection.value) {
        const noteModel = new ErrorCorrectionNoteViewModel();
        noteModel.description = note;
        const error: ErrorCorrectionViewModel = toRaw(errorCorrection.value);
        error.notes =
          error.notes !== null
            ? error?.notes
            : new Array<ErrorCorrectionNoteViewModel>();
        error?.notes.push(noteModel);
        update(error).then(() => {
          load();
        });
      }
    };
    const allErrorDone = async () => {
      if (errorCorrection.value) {
        const noteModel = new ErrorCorrectionNoteViewModel();
        noteModel.description = 'Alle fejl på anlæget bekræftet udbedret';
        const error: ErrorCorrectionViewModel = toRaw(errorCorrection.value);
        error.notes =
          error.notes !== null
            ? error?.notes
            : new Array<ErrorCorrectionNoteViewModel>();
        error.allErrorsCorrected = true;
        const userString = `${userModule.fullName} ${userModule.email}`;
        await update(error, userString);
        receipt.value = true;
      }
    };
    return {
      errorCorrection,
      addErrorRegistration,
      toDateString,
      allErrorDone,
      receipt,
      inspection
    };
  }
});
</script>
<style lang="scss" scoped>
#errorCorrection {
  &.col-md-6 {
    transition: all 0.5s linear;
  }
}
</style>
