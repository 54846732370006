import axios, { AxiosResponse } from 'axios';
import IComandResult from '@/Model/IComandResultTyped';
import useAuthToken from '@/utillities/useAuthToken';
import CustomerViewModel from '../Model/CustomerViewModel';
export default (): any => {
  const getCustomers = async (): Promise<Array<CustomerViewModel>> => {
    await useAuthToken();
    return new Promise<Array<CustomerViewModel>>((resolve, reject) => {
      axios
        .get('/api/customer')
        .then(
          (
            response: AxiosResponse<IComandResult<Array<CustomerViewModel>>>
          ) => {
            if (response.status == 200) {
              if (response.data.success) {
                resolve(response.data.data);
              } else {
                reject(response.data.errors);
              }
            }
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  };

  return { getCustomers };
};
