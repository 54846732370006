
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  ref,
  toRaw
} from 'vue';
import { useRoute } from 'vue-router';
import { customerState, userModule } from '@/store';
import ErrorCorrectionViewModel from '@/Model/ErrorCorrectionViewModel';
import ErrorCorrectionNoteViewModel from '@/Model/ErrorCorrectionNoteViewModel';
import toDateString from '@/utillities/toDateString';
import useErrorCorrection from '@/store/useErrorCorrection';
import InspectionViewModel from '@/Model/InspectionViewModel';

export default defineComponent({
  name: 'ErrorCorrection',
  components: {
    ErrorRegistration: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "errorCorrection" */ '@/views/ErrorCorrection/components/errorRegistration.vue'
        )
    )
  },
  setup() {
    const { params } = useRoute();
    const id = params.inspection.toString();
    const receipt = ref<boolean>(false);
    const inspection = computed<InspectionViewModel | null>(
      () => customerState.inspection
    );
    const {
      errorCorrection,
      saveErrorCorrection: update,
      getErrorCorrection: load
    } = useErrorCorrection(id);

    const addErrorRegistration = (note: string) => {
      if (errorCorrection.value) {
        const noteModel = new ErrorCorrectionNoteViewModel();
        noteModel.description = note;
        const error: ErrorCorrectionViewModel = toRaw(errorCorrection.value);
        error.notes =
          error.notes !== null
            ? error?.notes
            : new Array<ErrorCorrectionNoteViewModel>();
        error?.notes.push(noteModel);
        update(error).then(() => {
          load();
        });
      }
    };
    const allErrorDone = async () => {
      if (errorCorrection.value) {
        const noteModel = new ErrorCorrectionNoteViewModel();
        noteModel.description = 'Alle fejl på anlæget bekræftet udbedret';
        const error: ErrorCorrectionViewModel = toRaw(errorCorrection.value);
        error.notes =
          error.notes !== null
            ? error?.notes
            : new Array<ErrorCorrectionNoteViewModel>();
        error.allErrorsCorrected = true;
        const userString = `${userModule.fullName} ${userModule.email}`;
        await update(error, userString);
        receipt.value = true;
      }
    };
    return {
      errorCorrection,
      addErrorRegistration,
      toDateString,
      allErrorDone,
      receipt,
      inspection
    };
  }
});
