<template>
  <div class="row">
    <div v-if="customer" class="col-4">
      <div class="illustration-md float-start pt-0">
        <i class="bi bi-building"></i>
      </div>
      <div class="float-start text-start ms-2">
        <h4 class="primary">{{ customer.name }}</h4>
        <h6>{{ customer.location }}</h6>
        <p>{{ customer.address }} {{ customer.zipCode }} {{ customer.city }}</p>
      </div>
    </div>
    <div class="col-6">
      <input
        type="text"
        class="form-control"
        placeholder="Filter"
        v-model="filter"
      />
    </div>
    <div class="col-2">
      <div class="float-end">
        <a class="btn btn-primary btn-sm" @click="exportAsExcell">
          <i class="bi bi-file-earmark-spreadsheet-fill"></i>
          export
        </a>
      </div>
    </div>
  </div>

  <table class="table table-sm table-hover" v-if="filteredInspections">
    <thead>
      <tr>
        <td class="text-start">Sags nr.</td>
        <td class="text-start">Navn</td>
        <td class="text-start">Adresse</td>
        <td class="text-start">Postnr og by</td>
        <td class="text-center">Type</td>
        <td class="text-center">Inspiceret</td>
        <td class="text-center">Næste Inspektion</td>
        <td class="text-start">
          <select
            class="form-select form-select-sm"
            aria-label="Default select example"
            v-model="statusSort"
          >
            <option value="">Alle</option>
            <option value="0">Ikke godkendt</option>
            <option value="1">Betinget godkendt</option>
            <option value="2">Godkendt</option>
            <option value="4">Godkendt med c-fejl</option>
          </select>
        </td>
      </tr>
    </thead>
    <tbody v-if="filteredInspections.length > 0">
      <router-link
        v-slot="{ navigate }"
        custom
        v-for="inspection in filteredInspections"
        :key="inspection.id"
        :to="{
          name: 'inspection_documents',
          params: { id: inspection.customerId, inspection: inspection.id }
        }"
      >
        <tr @click="navigate">
          <td class="text-start">
            {{ inspection.externalRef }}
          </td>
          <td class="text-start">
            {{ inspection.name }}
            <br />
            <small>{{ inspection.location }}</small>
          </td>
          <td class="text-start">
            {{ inspection.address }}
          </td>
          <td class="text-start">
            {{ inspection.zipCode }} {{ inspection.city }}
          </td>
          <td class="text-center">{{ inspection.type }}</td>
          <td class="text-center">
            {{ toDateString(inspection.inspected) }}
          </td>
          <td class="text-center">
            {{ toDateString(inspection.nextInspection, 'MMM-yyyy') }}
          </td>
          <td class="text-start">
            <StatusDisplay
              :status="inspection.status"
              :experationDate="inspection.expirationDate"
            ></StatusDisplay>
          </td>
        </tr>
      </router-link>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="8">
          <div class="card">
            <div class="card-body">
              Der blevet ikke fundet nogen inspektioner
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script lang="ts">
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  ref,
  watch
} from 'vue';
import useInspection from '@/store/useInspection';
import InspectionViewModel from '@/Model/InspectionViewModel';
import { useRoute } from 'vue-router';
import toDateString from '@/utillities/toDateString';
import { onBeforeRouteLeave } from 'vue-router';
import { appState, customerState } from '@/store';

import CustomerViewModel from '@/Model/CustomerViewModel';

export default defineComponent({
  name: 'listInspections',
  components: {
    StatusDisplay: defineAsyncComponent(
      () =>
        import(
          /* webpackChunkName: "inspection" */ '@/components/StatusDisplay.vue'
        )
    )
  },
  setup() {
    const { getInspections, exportToExcel } = useInspection();
    const inspections = ref<Array<InspectionViewModel>>();
    const filteredInspections = ref<Array<InspectionViewModel>>();
    const { params } = useRoute();
    const id = parseInt(params.id.toString(), 10);
    const statusSort = ref<string>('');
    const filter = ref<string>('');
    const customer = computed<CustomerViewModel | null>(
      () => customerState.customer
    );
    const exportAsExcell = () => {
      exportToExcel(id)
        .then((result: Blob) => {
          const blob = new Blob([result], {
            type: 'application/vnd.ms-excel'
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'inspections.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error: string[]) => {
          appState.raiseError(error);
        });
    };

    customerState.inspection = null;
    const filterAndSort = (filter: string, sort: number) => {
      if (filter === '' && sort !== -1) {
        const sortedList = inspections.value?.filter(
          (inspection: InspectionViewModel) => {
            if (inspection.status === sort) {
              return inspection;
            }
          }
        );
        filteredInspections.value = sortedList;
      } else if (filter !== '') {
        const filteredList = inspections.value?.filter(
          (inspection: InspectionViewModel) => {
            const searchValues = `${inspection.name} ${inspection.location} ${inspection.address} ${inspection.zipCode} ${inspection.city}`.toLowerCase();
            if (searchValues.includes(filter.toLowerCase())) {
              return inspection;
            }
          }
        );
        if (filteredList && sort !== -1) {
          const sortedList = filteredList.filter(
            (inspection: InspectionViewModel) => {
              if (inspection.status === sort) {
                return inspection;
              }
            }
          );
          filteredInspections.value = sortedList;
        } else {
          filteredInspections.value = filteredList;
        }
      } else {
        filteredInspections.value = inspections.value;
      }
    };
    watch(
      () => statusSort.value,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          let status: number = newValue != '' ? parseInt(newValue, 10) : -1;
          filterAndSort(filter.value, status);
        }
      }
    );
    watch(
      () => filter.value,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          let status: number =
            statusSort.value != '' ? parseInt(statusSort.value, 10) : -1;
          filterAndSort(filter.value, status);
        }
      }
    );
    onBeforeRouteLeave((to) => {
      if (to.params.inspection && inspections && inspections.value) {
        const id = parseInt(to.params.inspection.toString(), 10);
        const index = inspections.value?.findIndex((inspection) => {
          return inspection.id === id;
        });
        if (index !== -1) {
          customerState.setInspection(inspections.value[index]);
        }
      }
    });
    onMounted(() => {
      getInspections(id).then((result: Array<InspectionViewModel>) => {
        inspections.value = result;
        filteredInspections.value = inspections.value;
      });
    });
    return {
      filteredInspections,
      customer,
      toDateString,
      exportAsExcell,
      statusSort,
      filter
    };
  }
});
</script>
<style lang="scss">
.illustration-md {
  @extend .illustration !optional;
  font-size: 50px;
}
.card {
  border: none;
}
</style>
