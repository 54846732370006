
import { defineComponent, computed, onMounted } from 'vue';
import useInspection from '@/store/useInspection';
import useCustomer from '@/store/useCustomer';

import { customerState } from '@/store';
import CustomerViewModel from '@/Model/CustomerViewModel';
import InspectionViewModel from '@/Model/InspectionViewModel';
import { useRoute } from 'vue-router';
export default defineComponent({
  setup() {
    const { params, name } = useRoute();
    const customer = computed<CustomerViewModel | null>(
      () => customerState.customer
    );
    const inspection = computed<InspectionViewModel | null>(
      () => customerState.inspection
    );
    const showInspection = computed<boolean>(() => {
      return name === 'inspection_error';
    });
    onMounted(() => {
      if (!customer.value) {
        const { get } = useCustomer();
        get(params.id).then((response: CustomerViewModel) => {
          customerState.setCustomer(response);
          if (!inspection.value && params.inspection) {
            const { get } = useInspection();
            get(params.inspection).then((response: InspectionViewModel) => {
              customerState.setInspection(response);
            });
          }
        });
      }
    });

    return {
      customer,
      inspection,
      showInspection
    };
  }
});
