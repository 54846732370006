import axios, { AxiosResponse } from 'axios';
import IComandResult from '@/Model/IComandResultTyped';
import useAuthToken from '@/utillities/useAuthToken';
import CustomerViewModel from '@/Model/CustomerViewModel';
export default (): any => {
  const get = async (id: number): Promise<CustomerViewModel> => {
    await useAuthToken();
    return new Promise<CustomerViewModel>((resolve, reject) => {
      axios
        .get(`/api/customer/${id}`)
        .then((response: AxiosResponse<IComandResult<CustomerViewModel>>) => {
          if (response.status == 200) {
            if (response.data.success) {
              resolve(response.data.data);
            } else {
              reject(response.data.errors);
            }
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  return { get };
};
